import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ItalicText mdxType="ItalicText">„Ich würde gerne einen Entwurf für eine Website betrachten können und sofort spüren: Das ist nicht
barrierefrei!“</ItalicText> – Diesen Wunsch äußerte ein Kollege vor ein paar Tagen. Er arbeitet bereits seit vielen
Jahren als Web-Entwickler, hat aber noch wenig Erfahrung mit digitaler Barrierefreiheit gesammelt.
    <p>{`Seine Frage brachte mich zum Nachdenken: Was können Neulinge tun, um möglichst schnell ein gutes Gespür für das
Thema zu entwickeln?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQABBv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFUNfU52Xi//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAMBAhMiMTP/2gAIAQEAAQUCmdW1ZcwMFeVie//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/Aar/xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAgEBPwEYv//EABkQAAEFAAAAAAAAAAAAAAAAAAACECExQf/aAAgBAQAGPwKCjBL/AP/EABwQAQABBAMAAAAAAAAAAAAAAAEAEBEhMVFhkf/aAAgBAQABPyFhu9sRvKv3G5r1GrPFBj//2gAMAwEAAgADAAAAEAT/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QUYUf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxCdFH//xAAaEAADAQADAAAAAAAAAAAAAAABESEAMWFx/9oACAEBAAE/EHWGyB3ALjOlOzhIB5pVGVurnAab/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Finger berühren die Seite eines Buches in Braille-Schrift.",
          "title": "Finger berühren die Seite eines Buches in Braille-Schrift.",
          "src": "/static/7e562c01339e6b9f3d0dea79455a5fd1/e5166/pexels-eren-li-fingers-reading-braille.jpg",
          "srcSet": ["/static/7e562c01339e6b9f3d0dea79455a5fd1/f93b5/pexels-eren-li-fingers-reading-braille.jpg 300w", "/static/7e562c01339e6b9f3d0dea79455a5fd1/b4294/pexels-eren-li-fingers-reading-braille.jpg 600w", "/static/7e562c01339e6b9f3d0dea79455a5fd1/e5166/pexels-eren-li-fingers-reading-braille.jpg 1200w", "/static/7e562c01339e6b9f3d0dea79455a5fd1/b17f8/pexels-eren-li-fingers-reading-braille.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Eren Li / pexels.com`}</em></p>
    <h2>{`Responsives Design? Wozu soll das gut sein?`}</h2>
    <p>{`Jüngere Kolleg:innen können sich das wohl schwer vorstellen: Es war nicht immer selbstverständlich, dass sich
Web-Inhalte fließend an verschiedene Bildschirmgrößen anpassen. Noch in den Nullerjahren war es üblich, Websites nur
für konkrete Bildschirmgrößen zu optimieren. Eine gute Zusammenfassung bietet der Artikel
`}<a parentName="p" {...{
        "href": "https://www.freecodecamp.org/news/a-brief-history-of-responsive-web-design/"
      }}><span lang="en">{`„A Brief History of Responsive Web Design“`}</span></a>{`.`}</p>
    <p>{`Erst der Siegeszug des Smartphones brachte ein Umdenken. Nutzer:innen erwarten heute, dass Websites auf allen Geräten
gut aussehen und bedienbar sind. Responsives Design wird explizit als Anforderung für Web-Projekte definiert. UI/UX-Expert:innen
gestalten Entwürfe und Prototypen für verschiedene Bildschirmgrößen. Entwickler:innen testen implementierte Features ganz
selbstverständlich auf Desktop- und Mobilgeräten.`}</p>
    <p>{`Was hat das jetzt mit Barrierefreiheit zu tun? Für mich zeigt der Siegeszug von responsivem Design deutlich: Je mehr Menschen
Websites auf eine bestimmte Weise nutzen, desto stärker werden diese Bedürfnisse in der Web-Entwicklung berücksichtigt.`}</p>
    <h2>{`Barrierefreies Design? Wozu soll das gut sein?`}</h2>
    <p>{`Die Mehrheit der Web-Nutzer:innen greift zum Smartphone oder zur Maus, um im Internet zu surfen. Sie klicken auf eine
Schaltfläche und registrieren das visuelle Feedback der Seite. Sie sind nicht darauf angewiesen, dass
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=dEbl5jvLKGQ"
      }}>{`ein Screenreader den Web-Inhalt sinnvoll vorlesen kann`}</a>{`
oder `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=FvpUNiB-2T0"
      }}>{`interaktive Elemente mit der Tastatur erreichbar`}</a>{` sind.`}</p>
    <p>{`Auch Web-Entwickler:innen sind im Grunde nur Nutzer:innen. Ihre alltäglichen Erfahrungen beeinflussen ihre Wahrnehmung,
was eine gute, bedienbare Website ausmacht. Ein Beispiel: Wer noch nie einen Screenreader genutzt hat, kann sich schwer
vorstellen, warum die `}<a parentName="p" {...{
        "href": "https://www.w3schools.com/html/html5_semantic_elements.asp"
      }}>{`semantische Auszeichnung von Web-Inhalten`}</a>{`
so wichtig ist.`}</p>
    <p>{`Das bringt mich zur zentralen Frage zurück: Was können Neulinge tun, um möglichst schnell ein gutes Gespür für das Thema
zu entwickeln? Ganz einfach: `}<strong parentName="p">{`Die Perspektive wechseln!`}</strong></p>
    <h2>{`Perspektive von Menschen mit Behinderung einnehmen`}</h2>
    <p>{`Wenn ihr die Bedürfnisse von Menschen mit Behinderung verstehen wollt, dann fragt sie am besten selbst, wie sie
digitale Inhalte nutzen. Lasst euch von einer blinden oder seh`}{`­`}{`beeinträchtigten Person zeigen, wie sie mithilfe
eines Screenreaders online einkauft. Sprecht mit einer gehörlosen Person darüber, wie sie Nachrichten
mit Untertiteln oder in Gebärdensprache schaut.`}</p>
    <p>{`Falls ihr keine Menschen mit Behinderung kennt, könnt ihr z.B. in eurer Firma einen Workshop organisieren.
Hier kann etwa der `}<a parentName="p" {...{
        "href": "https://www.blindenverband.at/"
      }}>{`Blinden- und Sehbehindertenverband Österreich`}</a>{` ein
Ansprechpartner sein. Manche Organisationen bieten auch konkrete Trainings an (Beispiel:
`}<a parentName="p" {...{
        "href": "https://www.myability.org/loesungen/disability-trainings/sensing-journey"
      }}>{`Sensing Journey von myAbility`}</a>{`).`}</p>
    <h2>{`Übung macht den Meister`}</h2>
    <p>{`Als Web-Entwickler:in ist man laufend mit neuen Anforderungen konfrontiert, die aus der rasanten, technischen
Entwicklung resultieren. Deshalb haben wir das Prinzip `}<span lang="en">{`„Learning by Doing“`}</span>{` verinnerlicht.
Diese Herangehensweise hilft uns auch beim Thema digitale Barrierefreiheit: Einfach mal machen!`}</p>
    <p>{`Ein guter Startpunkt sind aus meiner Sicht die
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/quickref/"
      }}><span lang="en">{`„Web Content Accessibility Guidelines“ (WCAG)`}</span></a>{`, die für
den öffentlichen Bereich in allen EU-Mitgliedsstaaten bereits rechtlich vorgeschrieben sind. Die WCAG decken eine große
Bandbreite an Bedürfnissen verschiedener Nutzer:innengruppen ab. Sie bieten sehr ausführliche Online-Ressourcen, mit
konkreten Beispielen für barrierefreie Implementierungen sowie häufige Fehler.`}</p>
    <p>{`Mein Tipp: Die meisten Web-Inhalte können allein mit semantischem HTML und CSS barrierefrei aufbereitet werden.
Oft stoße ich auf Websites, die exzessiv `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/Accessibility/ARIA"
      }}>{`ARIA-Attribute`}</a>{`
einsetzen und damit mehr Barrieren erzeugen als sie beseitigen. Sinnvoll ist der Einsatz von ARIA bei komplexen
UI-Komponenten, wie die `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-practices-1.1/"
      }}><span lang="en">{`WAI-ARIA Authoring Practices`}</span></a>{`
veranschaulichen.`}</p>
    <h2>{`Lass dir Zeit`}</h2>
    <p>{`Das Thema digitale Barrierefreiheit ist komplex und vielschichtig. Das bedeutet für Neulinge eine steile Lernkurve und
viel Frustpotential. Ihr solltet euch Schritt für Schritt dem Thema annähern und offen für neue Perspektiven sein. Dann
entwickelt ihr das richtige Gespür mit der Zeit ganz von allein.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      